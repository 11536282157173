import React from "react"
import { IconButton } from "@chakra-ui/react"
import { useAppContext } from "@app/providers/app"
import { useWishlist } from "@app/hooks/useWishlist"
import { HeartIcon, HeartIconFilled } from "@app/theme/icons"

type Props = {
  product: any
  loading?: boolean
  small?: boolean
}

const ProductWishlist: React.FC<Props> = ({ product, loading = false, small = false }) => {
  const { existsInWishlist, addToWishlist, deleteFromWishlist, loading: wishlistLoading } = useWishlist()
  const { activeVariant } = useAppContext()
  return (
    <IconButton
      aria-label="wishlist"
      onClick={() =>
        existsInWishlist(product?.id)
          ? deleteFromWishlist(product?.id)
          : addToWishlist({
              ...product,
              selectedSku: activeVariant?.sku,
              selectedTitle: activeVariant?.title,
            })
      }
      disabled={wishlistLoading || loading}
      variant="outline"
      size={small ? "sm" : "lg"}
      border="none"
      _hover={{ "& svg": { fill: "#b1b1b1" } }}
      _focus={{}}
      _active={{ "& svg": { fill: "#b1b1b1" } }}
      icon={
        existsInWishlist(product?.id) ? (
          <HeartIconFilled height={small ? ["16px", "24px"] : "30px"} width={small ? ["16px", "24px"] : "30px"} />
        ) : (
          <HeartIcon height={small ? ["16px", "24px"] : "30px"} width={small ? ["16px", "24px"] : "30px"} />
        )
      }
    />
  )
}

export default React.memo(ProductWishlist)
