import React, { useState, useCallback, useEffect, useMemo } from "react"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  useDisclosure,
  Flex,
  CloseButton,
  Heading,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Text,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
} from "@chakra-ui/react"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { useFunctions } from "@app/hooks/useFunctions"
import { useAppContext } from "@app/providers/app"
import { useStores } from "@app/hooks/useStores"
import Link from "@app/components/Link"
import { graphql, useStaticQuery } from "gatsby"
import { useAnalytics } from "@app/hooks/useAnalytics"

const FindInStoreDrawer: React.FC = ({ product, variant }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { headerOffset } = useAppContext()
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [stocked, setStocked] = useState([])
  const { activeVariant } = useAppContext()
  const { callFunction } = useFunctions()
  const { trackFindInStoreView } = useAnalytics()
  const [focus, setFocus] = useState(false)
  const [limit, setLimit] = useState(100)

  const {
    handleCurrentStore,
    autocompleteConfig,
    handleAutocomplete,
    handleGeo,
    handleReset,
    handleSearch,
    handleSubmit,
    handleSelection,
    loading: locationLoading,
    search,
    position,
    stores: allStores,
  } = useStores()

  const { template } = useStaticQuery<GatsbyTypes.StaticFindInStoreStockThresholdQuery>(graphql`
    query StaticFindInStoreStockThreshold {
      template: sanityTemplateProduct {
        lowStockThreshold: settingLowStockThreshold
        callToConfirmThreshold: settingCallToConfirmThreshold
      }
    }
  `)

  const { lowStockThreshold, callToConfirmThreshold } = template || {}

  const handleBlur = useCallback(() => setTimeout(() => setFocus(false), 500), [setFocus])

  const handleFocus = useCallback(() => setFocus(true), [setFocus])

  const handleKeyUp = useCallback(
    ({ keyCode }) => (keyCode === 13 ? focus && setFocus(false) : !focus && setFocus(true)),
    [focus, setFocus]
  )

  const fetchStock = useCallback(
    async sku => {
      if (sku) {
        setLoading(true)
        const { body, status } = await callFunction("checkStock", { sku })

        if (status === "success") {
          const stocked = body?.map(({ number, stock }: { number: string; stock: string }) => ({
            id: number,
            stock: stock,
          }))
          setStocked(stocked)
        } else {
          //@ts-ignore
          setErrors([body?.toString()])
        }
        setLoading(false)
      } else {
        setStocked([])
      }
    },
    [setLoading, setStocked, callFunction]
  )

  const stores = useMemo(
    () => allStores?.filter(({ ap21Number }) => stocked?.find(({ id }) => id === ap21Number))?.filter(({ distance }) => distance < limit),
    [allStores, stocked, limit]
  )

  useEffect(() => {
    if (isOpen) {
      trackFindInStoreView(product, variant)
    }
  }, [isOpen])

  useEffect(() => {
    if (activeVariant?.sku && isOpen) fetchStock(activeVariant.sku)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariant, isOpen])

  const getStockAvailability = ap21Number => {
    const stock = stocked.find(stockedStore => stockedStore?.id === ap21Number)?.stock || 0
    if (stock <= callToConfirmThreshold) return "Call to confirm"
    if (stock <= lowStockThreshold) return "Low Stock"
    return "Available"
  }

  return (
    <>
      <Button w="100%" mt={["32px", 0]} variant="outline" onClick={onOpen}>
        Find in store
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" placement="left" size="lg">
        <DrawerOverlay sx={{ "&": { top: `${headerOffset}px !important` } }} />
        <DrawerContent p={["0", "12"]} sx={{ "&": { top: `${headerOffset}px !important` } }}>
          <DrawerHeader pb={2}>
            <Flex justify="space-between" align="center" borderBottom={"1px solid black"} pb={4}>
              <Heading size="h3">Find In Store</Heading>
              <CloseButton onClick={onClose} />
            </Flex>
            {stores.length && position ? (
              <>
                <SimpleGrid columns={[2, 3]} gap={[4, 6]} borderBottom={"1px solid black"} py={6}>
                  <Box>
                    <Text size="12" fontWeight="600" textTransform="uppercase">
                      {product.title}
                    </Text>
                  </Box>
                  <Box d={["none", "block"]}></Box>
                  <HStack spacing={4} justify={["flex-end", "space-between"]}>
                    <VStack align="start" spacing="0">
                      <Text size="12" fontWeight="600" textTransform="uppercase">
                        COLOUR
                      </Text>
                      <Text size="12" fontWeight="600" textTransform="uppercase">
                        {variant.selectedOptions?.find(option => option?.name?.toLowerCase() === "colour")?.value}
                      </Text>
                    </VStack>
                    <VStack align="start" spacing="0">
                      <Text size="12" fontWeight="600" textTransform="uppercase">
                        SIZE
                      </Text>
                      <Text size="12" fontWeight="600" textTransform="uppercase">
                        {variant.selectedOptions?.find(option => option?.name?.toLowerCase() === "size")?.value}
                      </Text>
                    </VStack>
                  </HStack>
                </SimpleGrid>

                <Button
                  variant="ghost"
                  size="sm"
                  isLoading={locationLoading}
                  onClick={handleReset}
                  px={4}
                  title={"Reset Search"}
                  w="full"
                  mt={2}
                >
                  Reset Search
                </Button>
              </>
            ) : null}
          </DrawerHeader>
          <DrawerBody>
            {stores.length && position ? (
              <Box w="full">
                {stores.map(store => {
                  return (
                    <Box
                      key={store.handle.current.toString()}
                      onClick={() => handleCurrentStore(store.id)}
                      mb={2}
                      borderBottom="1px solid"
                      borderColor="grey.300"
                      py="4"
                    >
                      <Grid templateColumns={["repeat(2, 1fr)", "repeat(3, 1fr)"]} autoFlow="column" gap={[4, 6]}>
                        <GridItem>
                          <VStack align="left">
                            <Heading as="h3" size="h4">
                              {store?.title}
                            </Heading>
                            <Text size="16" letterSpacing="0.1em" textTransform="uppercase">
                              {getStockAvailability(store.ap21Number)}
                            </Text>
                            <Box>
                              <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                {store.distance} Km away
                              </Text>
                              <Text
                                as={Link}
                                to={`${store.url}/${store.handle.current}`}
                                title={store?.title}
                                size="10"
                                fontWeight="700"
                                letterSpacing="0.1em"
                                textTransform="uppercase"
                              >
                                View store
                              </Text>
                            </Box>
                            <Box display={["block", "none"]}>
                              {store?.address && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.address}
                                </Text>
                              )}
                              {store?.suburb && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.suburb}
                                </Text>
                              )}
                              {store?.state && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.state}, {store?.postcode}
                                </Text>
                              )}
                              {store?.phone && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase" mt={2}>
                                  {store?.phone}
                                </Text>
                              )}
                            </Box>
                          </VStack>
                        </GridItem>
                        <GridItem display={["none", "block"]}>
                          <VStack align="left" pt={"2px"}>
                            <Box>
                              {store?.address && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.address}
                                </Text>
                              )}
                              {store?.suburb && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.suburb}
                                </Text>
                              )}
                              {store?.state && (
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {store?.state}, {store?.postcode}
                                </Text>
                              )}
                            </Box>
                            {store?.phone && (
                              <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase" mt={2}>
                                {store?.phone}
                              </Text>
                            )}
                          </VStack>
                        </GridItem>
                        <GridItem>
                          <VStack align="left" spacing={[4, 2]} pt={"2px"}>
                            {store?.openhours.map((openhour, index) => (
                              <HStack key={index} justify="space-between">
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" textTransform="uppercase">
                                  {openhour.day}:
                                </Text>
                                <Text size="10" fontWeight="700" letterSpacing="0.1em" key={index} textTransform="uppercase">
                                  {openhour.hours}
                                </Text>
                              </HStack>
                            ))}
                          </VStack>
                        </GridItem>
                      </Grid>
                    </Box>
                  )
                })}
              </Box>
            ) : (
              <Box as="form" onSubmit={handleSubmit} w="100%">
                <Stack spacing={5} direction={"column"}>
                  <FormControl id="location" width="auto">
                    <FormLabel>Location</FormLabel>
                    <GooglePlacesAutocomplete
                      {...autocompleteConfig}
                      loader={<></>}
                      onSelect={handleAutocomplete}
                      renderInput={props => (
                        <Input
                          {...props}
                          errors={errors}
                          fontSize="md"
                          h={12}
                          isDisabled={loading}
                          onChange={handleSearch}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          onKeyDown={props.onChange}
                          onKeyUp={handleKeyUp}
                          placeholder="Suburb or postcode"
                          value={search}
                          type="search"
                          css={{
                            "&::-webkit-search-results-decoration,&::-webkit-search-results-button,&::-webkit-search-cancel-button,&::-webkit-search-decoration":
                              {
                                display: "none",
                              },
                            "&::-ms-clear,&::-ms-reveal": {
                              display: "none",
                            },
                          }}
                        />
                      )}
                      renderSuggestions={(_, suggestions, onSelectSuggestion) => (
                        <>
                          {suggestions?.length > 0 && focus && (
                            <VStack
                              pos="absolute"
                              justifyContent="flex-start"
                              insetX={0}
                              spacing={0}
                              py={2}
                              bg="white"
                              zIndex={1}
                              overflow="hidden"
                            >
                              {suggestions.map(suggestion => (
                                <Text
                                  key={suggestion.place_id}
                                  as="button"
                                  d="block"
                                  variant="ghost"
                                  size="14"
                                  fontWeight="normal"
                                  letterSpacing="normal"
                                  lineHeight="normal"
                                  py={2}
                                  px={4}
                                  textAlign="left"
                                  textTransform="none"
                                  title={suggestion.description}
                                  onClick={event => handleSelection(event, suggestion, onSelectSuggestion)}
                                  w="full"
                                  isTruncated
                                  _hover={{
                                    color: "grey.text",
                                  }}
                                >
                                  {suggestion.description}
                                </Text>
                              ))}
                            </VStack>
                          )}
                        </>
                      )}
                    />
                  </FormControl>

                  <FormControl id="distance">
                    <FormLabel>Within</FormLabel>
                    <Select
                      variant="unstyled"
                      height="40px"
                      name="distance"
                      fontSize="14px"
                      _placeholder={{
                        color: "grey.700",
                      }}
                      onChange={({ target: { value } }) => setLimit(parseInt(value))}
                      borderBottom="1px solid #333132"
                      borderRadius={0}
                    >
                      <option value="5">5km</option>
                      <option value="10">10km</option>
                      <option value="15">15km</option>
                      <option value="20">20km</option>
                      <option value="50">50km</option>
                      <option value="9999">100km+</option>
                    </Select>
                  </FormControl>

                  {!stores.length && position && !locationLoading && !loading && (
                    <Box pt={8}>
                      <Heading as="h3" size="h4" textAlign="center">
                        No results found within {limit}km
                      </Heading>
                    </Box>
                  )}

                  <Box display="flex" justifyContent="center">
                    <Button
                      type="submit"
                      variant="outline"
                      width={["full", "250px"]}
                      isDisabled={loading}
                      isLoading={loading}
                      _hover={{ color: "grey.700" }}
                      mt={8}
                    >
                      Find In Store
                    </Button>
                  </Box>
                </Stack>

                <Button
                  variant="ghost"
                  size="sm"
                  isLoading={locationLoading}
                  onClick={handleGeo}
                  px={4}
                  title={"Use current location"}
                  w="full"
                  my={8}
                >
                  Use current location
                </Button>
              </Box>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default React.memo(FindInStoreDrawer)
