import React from "react"
import { Flex, Box, Text } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { useRoutes } from "@app/hooks/useRoutes"

type Props = {
  collections: {
    title: string
    handle: string
  }[]
}

const ProductCollections: React.FC<Props> = ({ collections }) => {
  const { urlResolver } = useRoutes()
  return collections.length > 0 ? (
    <Box my={4}>
      <Text size="14">Collection:</Text>
      <Flex wrap="wrap">
        {collections.map((collection, index) => (
          <Link key={collection.handle || index} to={urlResolver(collection)?.url} d="flex" flexDirection="row">
            <Text size="14" textDecoration="underline">
              {collection.title}
            </Text>
            <Text size="14">
              {collections.length !== index + 1 && ","}
              &nbsp;
            </Text>
          </Link>
        ))}
      </Flex>
    </Box>
  ) : null
}

export default React.memo(ProductCollections)
