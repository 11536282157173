import React from "react"
import { Heading, Text } from "@chakra-ui/react"

type Props = {
  product: {
    title: string
    type: string
  }
}

const ProductTitle: React.FC<Props> = ({ product }) => (
  <>
    <Heading as="h1" fontSize={[20, 24]} mb={4} maxW="440px">
      {product?.title}
    </Heading>

    {product?.type && (
      <Text fontWeight="bold" my={4}>
        {product.type}
      </Text>
    )}
  </>
)

export default React.memo(ProductTitle)
