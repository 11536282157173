import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { useLazyQuery } from "@apollo/client"
import { useCore } from "@app/hooks/useCore"
import { useShopify } from "@app/hooks/useShopify"
import Template from "@app/components/Product/Product"
import { useSanityContext } from "@app/providers/sanity"

export type Props = PageProps<GatsbyTypes.TemplateProductQuery, GatsbyTypes.TemplateProductQueryVariables> & {
  loading: boolean
}

export const query = graphql`
  query TemplateProduct($handle: String!, $parentQuery: String!, $siblingQuery: String!) {
    product: sanityProduct(shopify: { handle: { eq: $handle } }) {
      ...SanityProductFragment
    }
    customData: sanityProduct(shopify: { handle: { eq: $handle } }) {
      productCustomData: product_custom_data {
        careInstructions: care_instructions
        composition
        fitDescription: fit_description
        productDetails: product_details
      }
    }
    parent: allSanityProduct(filter: { shopify: { published: { eq: true }, deleted: { ne: true } }, tags: { eq: $parentQuery } }) {
      nodes {
        ...SanityProductFragment
      }
    }
    colours: allSanityProduct(filter: { shopify: { published: { eq: true }, deleted: { ne: true } }, tags: { eq: $siblingQuery } }) {
      nodes {
        ...SanityProductFragment
      }
    }
    content: allSanityProduct(filter: { shopify: { handle: { eq: $handle } } }, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
          related: _rawRelated(resolveReferences: { maxDepth: 3 })
        }
      }
    }
    template: sanityTemplateProduct {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalRelatedProductsTitle
      settingShowRelatedProducts
      shipping: _rawShipping(resolveReferences: { maxDepth: 2 })
    }
  }
  fragment SanityProductFragment on SanityProduct {
    id
    url
    title
    descriptionHtml
    tags
    image
    shopify {
      id
      handle
      raw
    }
  }
`
const Component: React.FC<Props> = ({ data, ...props }) => {
  const {
    graphql: {
      queries: { GET_PRODUCT_AVAILABILITY },
    },
  } = useCore()
  const { variantsNormaliser, productNormaliser } = useShopify()
  const { client } = useSanityContext()
  const [featuredCollections, setFeaturedCollections] = useState(null)

  const {
    product: {
      //@ts-ignore
      shopify: { handle, raw },
    },
  } = data

  let rawProduct = undefined

  try {
    rawProduct = JSON.parse(raw)
  } catch (err) {
    console.error("Error parsing raw shopify product")
  }

  const collectionsHandleArray = rawProduct?.collections?.map(collection => `"${collection.handle}"`) || []

  const query = `*[_type == 'collection' && !(_id in path('drafts.**')) && shopify.handle in [${collectionsHandleArray}]] {
    _type,
    title,
    shopify { handle },
    parentCollection -> {
      _type,
      title,
      shopify {handle},
      parentCollection -> {
        _type,
        title,
         shopify {handle},
        parentCollection -> {
          _type,
          title,
           shopify {handle},
        }
      }
    }
  }`

  const [getProduct, { data: live, called, loading, error }] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { handle },
  })

  useEffect(() => {
    if (!handle || called) return
    getProduct()
    client.fetch(query).then(items => setFeaturedCollections(items))
  }, [handle, called, getProduct, client, query])

  if (error) console.error(error)

  let liveProduct = undefined
  try {
    liveProduct = live && {
      ...rawProduct,
      variants: rawProduct?.variants?.map((variant: any, index: number) => ({
        ...variant,
        ...variantsNormaliser(live.product?.variants)[index],
      })),
    }
  } catch (err) {
    console.error("Error parsing live shopify product")
  }

  return (
    <Template
      {...props}
      {...data}
      colours={{
        nodes: data.colours.nodes?.filter((tag, index, array) => array.findIndex(t => t.shopify.handle === tag.shopify.handle) == index),
      }}
      product={productNormaliser(liveProduct || rawProduct)}
      featuredCollections={featuredCollections}
      loading={loading}
    />
  )
}
export default Component
