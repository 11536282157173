import React, { useCallback, useEffect, useRef, useState } from "react"
import { Box, Button, Flex, Image, RadioProps, Select, Text, SimpleGrid, useRadio, useRadioGroup } from "@chakra-ui/react"
import { Link } from "gatsby"
import { useCart } from "@app/hooks/useCart"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { getVariantBySelectedOptions } from "@app/hooks/useShopify"

type Props = {
  product: any
  loading: boolean
  siblings: any[]
  activeVariant: any
  selectedOptions: any
  handleOption: any
  handleGiftCard: (state: boolean) => void
}

const Radio: React.FC<RadioProps> = props => {
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        borderBottom="1px solid transparent"
        cursor="pointer"
        _hover={{
          borderBottom: "1px solid #b1b1b1",
        }}
        _checked={{
          fontWeight: "700",
          borderBottom: "1px solid #b1b1b1",
        }}
        _focus={{
          boxShadow: "outline",
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}

interface RadioGroupProps {
  name: string
  options: string[]
  loading: boolean
  handleOption: (option: any) => void
  value: any
}

const RadioGroup: React.FC<RadioGroupProps> = ({ options, loading, name, handleOption, value }) => {
  const onChange = useCallback(value => handleOption({ name, value }), [handleOption, name])
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    value,
  })

  const group = getRootProps()

  return name === "Value" ? (
    <SimpleGrid {...group} columns={6} spacing={3} wrap="wrap" fontSize="14px" my={4}>
      {options.map(option => {
        const radio = getRadioProps({ value: option.value })
        return (
          <Flex justify="start" opacity={option.available ? 1 : 0.3} key={option.value}>
            <Radio {...radio}>{option.value}</Radio>
          </Flex>
        )
      })}
    </SimpleGrid>
  ) : (
    <Flex {...group} wrap="wrap" fontSize="14px" my={4} ml={-3} isDisabled={loading}>
      {options.map(option => {
        const radio = getRadioProps({ value: option.value })
        return (
          <Flex justify="center" opacity={option.available ? 1 : 0.3} key={option.value} mx="3">
            <Radio {...radio}>{option.value}</Radio>
          </Flex>
        )
      })}
    </Flex>
  )
}

const ProductVariants: React.FC<Props> = ({ product, loading, siblings, activeVariant, selectedOptions, handleOption, handleGiftCard }) => {
  const {
    helpers: { handleize },
  } = useCore()
  const { urlResolver } = useRoutes()
  const {
    settings: { routes },
  } = useConfigContext()
  const { state, dispatch } = useAppContext()
  const [isMountedToBottom, setIsMountedToBottom] = useState(true)
  const [hoveredColor, setHoveredColor] = useState(null)

  let size = product.options.find((option: any) => option?.name === "Size")
  if (size) {
    size = {
      name: size.name,
      values: size.values.map(value => {
        return {
          value,
          available: !!getVariantBySelectedOptions(
            [...selectedOptions.filter(selectedOption => selectedOption.name === "Colour"), { name: size.name, value }],
            product.variants
          )?.availableForSale,
        }
      }),
    }
  }

  let value = product.options.find((option: any) => option?.name === "Value")
  if (value) {
    value = {
      name: value.name,
      values: value.values.map(v => {
        return {
          value: v,
          available: !!getVariantBySelectedOptions(
            [...selectedOptions.filter(selectedOption => selectedOption.name === "Colour"), { name: value.name, value: v }],
            product.variants
          )?.availableForSale,
        }
      }),
    }
  }

  const currentColour = product.options.find((option: any) => option?.name === "Colour")?.values?.[0]

  const { addToCart, loading: cartLoading } = useCart()

  const addToCartButtonRef = useRef(null)

  const handleScroll = () => {
    const distance = addToCartButtonRef.current.getBoundingClientRect().top
    const mount = distance - window.innerHeight < -220
    setIsMountedToBottom(!mount)
  }

  const handleSizeguide = useCallback(() => {
    dispatch({
      type: "sizeguide",
      payload: !state.activeSizeguide,
    })
  }, [dispatch, state])

  const colors =
    siblings?.length > 1
      ? siblings?.map(product => {
          if (product?.options) {
            return {
              key: product.id,
              color: product.options.find((option: any) => option?.name === "Colour" || option?.name === "Color")?.values?.[0],
              link: urlResolver(product, routes.PRODUCT),
            }
          }

          if (product?.shopify?.raw) {
            let rawProduct = undefined
            try {
              rawProduct = JSON.parse(product.shopify.raw)
            } catch (err) {
              console.error("Error parsing raw shopify product")
            }
            return {
              key: rawProduct.id,
              color: rawProduct.options.find((option: any) => option?.name === "Colour" || option?.name === "Color")?.values?.[0],
              link: urlResolver(rawProduct, routes.PRODUCT),
            }
          }

          return undefined
        })
      : [
          {
            key: product.id,
            color: product.options.find((option: any) => option?.name === "Colour" || option?.name === "Color")?.values?.[0],
            link: urlResolver(product, routes.PRODUCT),
          },
        ]

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const selectedSize = selectedOptions?.find(({ name }: { name: string }) => name === "Size")?.value
  const selectedValue = selectedOptions?.find(({ name }: { name: string }) => name === "Value")?.value

  return (
    <Box mt="6" ref={addToCartButtonRef}>
      <Box display={["none", "block"]} pt={4} pb={6}>
        {size && (
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <Text variant="label">Sizes</Text>
              <Text
                as={"button"}
                onClick={handleSizeguide}
                variant="label"
                fontSize={["11", "11"]}
                fontWeight="700"
                letterSpacing="0.2em"
                textTransform="uppercase"
              >
                Size Guide
              </Text>
            </Flex>
            <RadioGroup
              options={size.values}
              loading={loading}
              handleOption={handleOption}
              name="Size"
              value={selectedOptions?.find(({ name }) => name === "Size")?.value || ""}
            />
          </>
        )}
        {value && (
          <>
            <Flex alignItems="center" justifyContent="space-between">
              <Text variant="label">Value</Text>
            </Flex>
            <RadioGroup
              options={value.values}
              loading={loading}
              handleOption={handleOption}
              name="Value"
              value={selectedOptions?.find(({ name }) => name === "Value")?.value || ""}
            />
          </>
        )}
      </Box>
      {!value && (
        <Box pt={4} pb={6} borderY="1px solid #e0e0e0">
          <Box>
            <Text variant="label" mb={4} d="inline-block">
              Colour
            </Text>
            {hoveredColor && (
              <Text variant="label" fontWeight={400} color="grey.500" ml={3} d="inline-block">
                {hoveredColor?.color}
              </Text>
            )}
          </Box>
          <Flex wrap="wrap" ml={-1.5}>
            {colors.map(color => (
              <Box
                key={color?.key}
                as={color?.color === currentColour ? "div" : Link}
                to={color?.link.url}
                borderBottom={color?.color === currentColour ? "1px solid black" : "none"}
                pb={2}
                mx={1.5}
                mb={3}
                onMouseEnter={() => setHoveredColor(color)}
                onMouseLeave={() => setHoveredColor(null)}
              >
                <Image
                  src={`https://res.cloudinary.com/calibre-clothing/image/upload/swatches/swatch-${handleize(color?.color || "")}.png`}
                  boxSize="24px"
                  fallbackSrc="https://plchldr.co/i/24?&amp;bg=EFEFEF&amp;fc=EFEFEF"
                  alt={color?.link?.title}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      )}

      <Flex
        borderBottom="1px solid #e0e0e0"
        display={["flex", "none"]}
        bg={"white"}
        alignItems="center"
        pt={isMountedToBottom ? 4 : 6}
        pb={isMountedToBottom ? 4 : 8}
        px={isMountedToBottom ? 6 : 0}
        pos={isMountedToBottom ? "fixed" : "static"}
        bottom={0}
        insetX={0}
        zIndex={10}
      >
        {size && (
          <>
            <Select
              variant="unstyled"
              w="300px"
              size="xs"
              fontSize="16"
              fontWeight="700"
              letterSpacing={selectedSize ? "0.2em" : 0}
              transition="none"
              isDisabled={loading}
              value={selectedSize ? selectedSize : "-"}
              onChange={({ target: { value } }) => handleOption({ name: "Size", value })}
            >
              <option key="-" value="-" disabled hidden>
                Select size
              </option>
              {size?.values.map((value: string, index: number) => (
                <option key={value?.value.toString() + index} value={value.value}>
                  Size: {value.value}
                </option>
              ))}
            </Select>
            <Button
              w="100%"
              ml="2"
              onClick={() => addToCart(activeVariant?.id)}
              disabled={!activeVariant?.availableForSale || loading}
              isLoading={cartLoading}
            >
              {!selectedSize ? "Select a size" : !activeVariant?.availableForSale ? "Sold out" : "Add to Cart"}
            </Button>
          </>
        )}
        {value && (
          <>
            <Select
              variant="unstyled"
              w="300px"
              size="xs"
              fontSize="16"
              fontWeight="700"
              letterSpacing={selectedSize ? "0.2em" : 0}
              transition="none"
              isDisabled={loading}
              value={selectedValue ? selectedValue : "-"}
              onChange={({ target: { value } }) => handleOption({ name: "Value", value })}
            >
              <option key="-" value="-" disabled hidden>
                Select value
              </option>
              {value?.values.map((value: string, index: number) => (
                <option key={value?.value.toString() + index} value={value.value}>
                  Value:&nbsp;&nbsp;{value.value}
                </option>
              ))}
            </Select>
            <Button
              w="100%"
              ml="1"
              onClick={() => handleGiftCard(true)}
              disabled={!activeVariant?.availableForSale || loading}
              isLoading={cartLoading}
            >
              {!selectedValue ? "Select a value" : !activeVariant?.availableForSale ? "Sold out" : "Add to Cart"}
            </Button>
          </>
        )}
      </Flex>
    </Box>
  )
}

export default React.memo(ProductVariants)
