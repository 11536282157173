import React from "react"
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Text, UnorderedList, ListItem } from "@chakra-ui/react"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { useCore } from "@app/hooks/useCore"

type Props = {
  product: {
    description: any
  }
  productCustomData: any
  shipping: any
}

const ProductAdditionalInfo: React.FC<Props> = ({ product, productCustomData, shipping }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const { careInstructions, composition, fitDescription, productDetails } = productCustomData || {}

  const productCode = product?.tags?.find(tag => tag.includes("group"))?.split(":")?.[1]

  const shippingText = sanityContent(shipping)

  return (
    <Accordion allowToggle allowMultiple defaultIndex={0} mt={4} mb={2}>
      {productDetails?.length || fitDescription?.length || productCode?.length ? (
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton d="flex" justifyContent="space-between" alignItems="center" px={0} py={4} _hover={{}} _active={{}}>
                <Text variant="label" size="14">
                  Details
                </Text>
                {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              </AccordionButton>
              <AccordionPanel px={0} pt={0} pb={4}>
                {productDetails && productDetails.length > 0 ? (
                  <UnorderedList color="grey.700">
                    {productDetails.map((detail: string) => (
                      <ListItem key={detail.trim()} fontSize="14">
                        {detail}
                      </ListItem>
                    ))}
                  </UnorderedList>
                ) : null}
                {fitDescription?.length > 0 && (
                  <Text fontSize="14" color="grey.700" mt={6}>
                    Fit: {fitDescription}
                  </Text>
                )}
                {productCode?.length > 0 && (
                  <Text fontSize="14" color="grey.700" mt={2}>
                    Style Code: {productCode}
                  </Text>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ) : null}
      {composition || careInstructions ? (
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton d="flex" justifyContent="space-between" alignItems="center" px={0} py={4} _hover={{}} _active={{}}>
                <Text variant="label" size="14">
                  COMPOSITION &amp; CARE
                </Text>
                {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              </AccordionButton>
              <AccordionPanel px={0}>
                {composition?.length > 0 && (
                  <Text fontSize="14" color="grey.700">
                    Composition: {composition}
                  </Text>
                )}
                {careInstructions?.length > 0 && (
                  <Text fontSize="14" color="grey.700" mt={2}>
                    Care Instruction: {careInstructions}
                  </Text>
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ) : null}
      {shippingText.length > 0 && (
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton d="flex" justifyContent="space-between" alignItems="center" px={0} py={4} _hover={{}} _active={{}}>
                <Text variant="label" size="14">
                  SHIPPING &amp; RETURNS
                </Text>
                {isExpanded ? <BsChevronUp /> : <BsChevronDown />}
              </AccordionButton>
              <AccordionPanel px={0}>
                <Box fontSize="14" color="grey.700">
                  {shippingText}
                </Box>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      )}
    </Accordion>
  )
}
export default React.memo(ProductAdditionalInfo)
