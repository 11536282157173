import { useCallback, useEffect, useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { convertDistance, getDistance } from "geolib"
import { geocodeByPlaceId, geocodeByAddress } from "react-google-places-autocomplete"

import { useAppContext } from "@app/providers/app"
import { useConfigContext } from "@app/providers/config"
import { useCore } from "@app/hooks/useCore"
import { useRoutes } from "@app/hooks/useRoutes"
import { useShopify } from "@app/hooks/useShopify"

type AutocompleteConfig = {
  apiKey: string
  autocompletionRequest: any
  minLengthAutocomplete: number
}

type LatLng = {
  lat: number
  lng: number
}

type LatLngResult = {
  lat: () => number
  lng: () => number
}

type GeocodeResult = {
  address_components: any
  formatted_address: string
  geometry: {
    location: LatLngResult
    viewport: { northeast: LatLngResult; southwest: LatLngResult }
  }
  place_id: string
  types: Array<string>
}

export type Store = GatsbyTypes.SanityStore & {
  distance: number
  link: any
}

export type UseStores = {
  autocompleteConfig: AutocompleteConfig
  currentStore: GatsbyTypes.SanityStore | undefined
  errors: Array<string> | null
  handleAutocomplete: (description: any) => void
  handleCurrentStore: (id: string) => void
  handleGeo: () => Promise<void>
  handleReset: () => Promise<void>
  handleSearch: (event: any) => void
  handleSelection: (event: any, suggestion: any, onSelectSuggestion: any) => void
  handleSubmit: (event: any) => void
  loading: boolean
  position: LatLng | null
  search: string
  stores: Array<Store>
}

export const useStores = (): UseStores => {
  const { stores: allStores } = useStaticQuery<GatsbyTypes.StaticStoresQuery>(graphql`
    query StaticStores {
      stores: allSanityStore {
        edges {
          node {
            ...SanityStoreFragment
          }
        }
      }
    }
  `)
  const { activeStore, setActiveStore } = useAppContext()
  const {
    services: { googleMaps },
    settings: { keys },
  } = useConfigContext()
  const {
    helpers: { storage },
  } = useCore()
  const { urlResolver } = useRoutes()
  const { edgeNormaliser } = useShopify()
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const [position, setPosition] = useState(null)
  const [search, setSearch] = useState(storage.get(keys.search) || "")

  const formatSearch = query => {
    query = query.trim()

    // Help Google Places API by prepending NSW to NSW postcodes
    if (query.match(/^2\d{3}$/)) {
      query = `NSW ${query}`
    }

    return `${query} Australia`
  }

  const handleDistance = useCallback(
    store =>
      store?.location && position
        ? Math.round(
            (convertDistance(
              getDistance(
                { latitude: position?.lat, longitude: position?.lng },
                { latitude: store?.location?.lat, longitude: store?.location?.lng }
              ),
              `km`
            ) +
              Number.EPSILON) *
              100
          ) / 100
        : 0,
    [position]
  )

  const handleAutocomplete = useCallback(({ description: value }) => setSearch(value), [setSearch])

  const handleCurrentStore = useCallback(
    id => {
      setActiveStore(id)
      storage.set(keys.store, id)
    },
    [keys, setActiveStore, storage]
  )

  const handleSearch = useCallback(({ target: { value } }) => setSearch(value), [setSearch])

  const handlePlace = useCallback(
    async (place: GeocodeResult) => {
      if (place?.geometry?.location?.lat && place?.geometry?.location?.lng) {
        storage.set(keys.search, place.formatted_address, 7)
        setSearch(place.formatted_address)
        setPosition({
          //@ts-ignore
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        })
      }
    },
    [keys, setPosition, setSearch, storage]
  )

  const handleSelection = useCallback(
    async (event, suggestion, onSelectSuggestion) => {
      onSelectSuggestion(suggestion, event)
      setSearch(suggestion.description)

      const place = await geocodeByPlaceId(suggestion?.place_id)

      //@ts-ignore
      handlePlace(place?.[0])
    },
    [handlePlace, setSearch]
  )

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (search) {
        geocodeByAddress(formatSearch(search))
          .then(results => {
            // Filter out non AU results
            results = results.filter(result =>
              result.address_components.find(comp => comp.types.includes("country") && comp.short_name == "AU")
            )

            //@ts-ignore
            handlePlace(results?.[0])
          })
          .catch(error => console.error(error))
      }
    },
    [handlePlace, search]
  )

  const handleGeo = useCallback(async () => {
    setErrors(null)
    setLoading(true)
    navigator.geolocation.getCurrentPosition((position: any) => {
      setLoading(false)
      setSearch("")
      setPosition({
        //@ts-ignore
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      })
    })
  }, [setErrors, setLoading])

  const handleReset = useCallback(async () => {
    setLoading(true)
    setErrors(null)
    setSearch("")
    setPosition(null)
    setLoading(false)
    storage.remove(keys.search)
    storage.remove(keys.position)
  }, [setErrors, setLoading, setSearch, storage, keys])

  const stores: Array<Store> = useMemo(
    () =>
      edgeNormaliser(allStores)
        ?.filter(({ location }: GatsbyTypes.SanityStore) => location?.lat !== null && location?.lng !== null)
        ?.map((store: GatsbyTypes.SanityStore) => ({
          ...store,
          distance: handleDistance(store),
          link: urlResolver(store),
        }))
        ?.sort((a, b) => a.distance - b.distance),
    [allStores, edgeNormaliser, handleDistance, urlResolver]
  )

  const currentStore = useMemo(() => stores?.find(({ id }) => id === activeStore), [stores, activeStore])

  const autocompleteConfig = useMemo(
    () => ({
      apiKey: googleMaps?.apiKey,
      autocompletionRequest: {
        componentRestrictions: {
          country: ["au"],
        },
      },
      minLengthAutocomplete: 3,
    }),
    [googleMaps]
  )

  useEffect(() => {
    if (position) storage.set(keys.position, position, 7)
  }, [keys, position, storage])

  useEffect(() => {
    if (!search && storage.get(keys.search)) storage.remove(keys.search)
  }, [keys, search, storage])

  useEffect(() => {
    const saved = storage.get(keys.position)
    if (saved && !position) setPosition(saved)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    autocompleteConfig,
    currentStore,
    errors,
    handleAutocomplete,
    handleCurrentStore,
    handleGeo,
    handleSearch,
    handleSelection,
    handleSubmit,
    handleReset,
    loading,
    position,
    search,
    stores,
  }
}
