import React, { useState, useCallback, useEffect } from "react"
import { Box, Container, Flex, Text } from "@chakra-ui/react"
import { useLocationContext } from "@app/providers/location"
import { useShopify, useShopifyVariants } from "@app/hooks/useShopify"
import ProductPrice from "./ProductPrice"
import ProductWishlist from "./ProductWishlist"
import ProductAfterPay from "./ProductAfterPay"
import ProductTitle from "./ProductTitle"
import ProductMedia from "./ProductMedia"
import ProductVariants from "./ProductVariants"
import ProductAddToCart from "./ProductAddToCart"
import ProductDescription from "./ProductDescription"
import ProductRecommended from "./ProductRecommended"
import type { Props } from "@app/templates/product"
import type { PageProps } from "@root/types/global"
import ProductAdditionalInfo from "./ProductAdditionalInfo"
import ProductCollections from "./ProductCollections"
import ProductGiftcard from "./ProductGiftcard"
import FindInStoreDrawer from "@app/components/FindInStore/FindInStoreDrawer"
import { useKlaviyo } from "@app/hooks/useKlaviyo"
import { useAppContext } from "@app/providers/app"

const Product: React.FC<PageProps<Props>> = ({ product, loading, template, content, customData, colours, featuredCollections }) => {
  const { edgeNormaliser } = useShopify()
  const { state, dispatch } = useAppContext()
  const [giftcardActive, setGiftcardActive] = useState(false)
  const { locating } = useLocationContext()
  const { klaviyoLoaded, trackProduct } = useKlaviyo()
  const [klaviyoTracked, setKlaviyoTracked] = useState(false)
  const isLoading = loading || locating

  const {
    localActiveVariant: activeVariant,
    selectedOptions,
    handleOption,
  } = useShopifyVariants({ useParameter: true, product, loading: isLoading, firstAvailable: false })

  const { related } = edgeNormaliser(content)?.[0] || {}
  const { additionalRelatedProductsTitle, settingShowRelatedProducts, shipping } = template || {}

  const { productCustomData } = customData

  const omittedCollections = ["autumn", "best", "frontpage", "get-ready", "edition", "arrival", "pre-order", "sale", "promotion"]

  const collections =
    featuredCollections?.filter(({ title }) => !omittedCollections.some(word => title?.toLowerCase()?.includes(word))) || []

  const defaultVariant = product.variants?.find(({ availableForSale }) => availableForSale) || product.variants?.[0]

  const handleGiftCard = useCallback(
    (active: boolean) => {
      setGiftcardActive(active)
    },
    [setGiftcardActive]
  )

  const handleSizeguide = useCallback(() => {
    dispatch({
      type: "sizeguide",
      payload: !state.activeSizeguide,
    })
  }, [dispatch, state])

  useEffect(() => {
    if (klaviyoTracked || !klaviyoLoaded || !product.variants?.[0]?.priceV2?.amount) {
      return
    }

    trackProduct(product)
    setKlaviyoTracked(true)
  }, [klaviyoLoaded, product])

  return (
    <>
      <ProductGiftcard variant={activeVariant || defaultVariant} active={giftcardActive} handleGiftCard={handleGiftCard} />
      <Container as="section" maxW="container.xxl" pr={[0, 4]} pl={[0, 16]} pb={[4, 12]} pt={[0, 4]}>
        <Flex flexDirection={["column", "row"]} pos="relative" spacing={[0, 4]}>
          <Box w={["100%", "60%"]}>
            <ProductMedia product={product} />
          </Box>
          <Box className="product__form-wrapper" w={["100%", "40%"]} pt={[6, 0]} pb={[6, 4]} px={[4, 16]} mt={[6, 0]}>
            <Box pos={["relative", "relative"]} top={["auto", "auto"]}>
              <ProductTitle product={product} />
              <Flex alignItems="center" justifyContent="space-between">
                <ProductPrice variant={activeVariant || defaultVariant} loading={isLoading} />
                <ProductWishlist product={product} loading={isLoading} />
              </Flex>
              <ProductAfterPay variant={activeVariant || defaultVariant} />
              <ProductVariants
                product={product}
                loading={loading}
                siblings={product?.grouped || edgeNormaliser(colours.nodes)}
                activeVariant={activeVariant}
                selectedOptions={selectedOptions}
                handleOption={handleOption}
                handleGiftCard={handleGiftCard}
              />
              <ProductAddToCart product={product} variant={activeVariant} handleGiftCard={handleGiftCard} loading={isLoading} />
              <FindInStoreDrawer product={product} variant={activeVariant || defaultVariant} />
              <Box d={["flex", "none"]} justifyContent="center" mt={6}>
                <Text
                  as={"button"}
                  onClick={handleSizeguide}
                  variant="label"
                  fontSize={["11", "11"]}
                  fontWeight="700"
                  letterSpacing="0.2em"
                  textTransform="uppercase"
                >
                  Size Guide
                </Text>
              </Box>
              <ProductDescription product={product} />
              <ProductAdditionalInfo product={product} productCustomData={productCustomData} shipping={shipping} />
              <ProductCollections collections={collections} />
            </Box>
            {/* <ProductStockOverlay /> */}
          </Box>
        </Flex>
      </Container>
      {settingShowRelatedProducts && (
        <ProductRecommended title={additionalRelatedProductsTitle || "Recommend Products"} id={product?.id} related={related} />
      )}
    </>
  )
}
export default React.memo(Product)
