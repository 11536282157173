import React, { useRef, useState, useCallback } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Box,
  Stack,
  FormControl,
  Input,
  Button,
  FormLabel,
} from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"

type Props = {
  variant: any
  active: boolean
  handleGiftCard: (active: boolean) => void
}

const INITIAL_STATE = { name: "", email: "", from: "", message: "" }

const ProductGiftcard: React.FC<Props> = ({ variant, active, handleGiftCard }) => {
  const [data, setData] = useState(INITIAL_STATE)
  const initialRef = useRef()
  const { addToCart, loading } = useCart()

  const handleChange = useCallback(
    ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    },
    [setData]
  )

  const handleClose = useCallback(() => {
    handleGiftCard(false)
  }, [handleGiftCard])

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()
      await addToCart(variant.id, 1, [
        { key: "Recipient", value: data.name },
        { key: "Recipient email", value: data.email },
        { key: "Sender", value: data.from },
        { key: "Message", value: data.message },
      ])
      handleClose()
    },
    [variant, data, addToCart, handleClose]
  )

  return (
    <Modal onClose={handleClose} isOpen={active} initialFocusRef={initialRef} motionPreset="slideInBottom" size="lg" isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center" p={[8, 16]} borderRadius="none">
        <ModalHeader>
          <Heading as="h3" size="h5" fontSize="11px" fontWeight="400" mb={6}>
            WHO IS THE LUCKY RECIPIENT?
          </Heading>
        </ModalHeader>
        <ModalBody>
          <Box as="form" onSubmit={handleSubmit} w="100%">
            <Stack spacing={5} direction={"column"}>
              <FormControl width="auto">
                <FormLabel>Recipient name</FormLabel>
                <Input
                  id="name"
                  value={data?.name}
                  onChange={handleChange}
                  type="text"
                  maxlength="50"
                  isRequired
                  name="name"
                  ref={initialRef}
                />
              </FormControl>
              <FormControl width="auto">
                <FormLabel>Recipient email</FormLabel>
                <Input id="email" value={data?.email} onChange={handleChange} type="email" isRequired name="email" />
              </FormControl>
              <FormControl width="auto">
                <FormLabel>From</FormLabel>
                <Input id="from" value={data?.from} onChange={handleChange} type="text" maxlength="50" isRequired name="from" />
              </FormControl>
              <FormControl width="auto">
                <FormLabel>Message</FormLabel>
                <Input id="message" value={data?.message} onChange={handleChange} type="text" maxlength="250" isRequired name="message" />
              </FormControl>
              <Button type="submit" flexShrink={0} width="auto" isDisabled={loading} isLoading={loading} _hover={{ color: "grey.700" }}>
                Add to Cart
              </Button>
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalCloseButton />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(ProductGiftcard)
