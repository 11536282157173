import React from "react"
import { GridItem, Box, Flex, Image, Text, LinkBox, LinkOverlay, AspectRatio } from "@chakra-ui/react"
import Link from "@app/components/Link"
import { useRoutes } from "@app/hooks/useRoutes"
import { useConfigContext } from "@app/providers/config"
import ProductPrice from "@app/components/Product/ProductPrice"
import { getResponsiveProductImages } from "@app/utils/getProductImages"

type Props = {
  item: any
  small?: boolean
  justify?: boolean
  handleTrackingClick: () => void
}

const FeaturedProductsItem: React.FC<Props> = ({ item, handleTrackingClick, small = true, justify = "left" }) => {
  const {
    settings: { routes },
  } = useConfigContext()
  const { urlResolver } = useRoutes()
  const { desktopImages } = getResponsiveProductImages(item?.images)

  return (
    <GridItem key={item?.title?.toString()} colSpan={1} width="100%" h="unset">
      <LinkBox>
        <AspectRatio d={["none", "block"]} ratio={1} w="100%" objectFit="cover" mb="16px">
          <Image
            src={desktopImages[0]?.src}
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300x300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio>
        <AspectRatio d={["block", "none"]} ratio={1} w="100%" objectFit="contain" mb={[4, 6]}>
          <Image
            src={desktopImages[0]?.src} // Using desktop images for square aspect ratio
            alt={item?.title}
            maxW="100%"
            fallbackSrc="https://plchldr.co/i/300x300?&amp;bg=EFEFEF&amp;fc=EFEFEF"
          />
        </AspectRatio>
        <Box textAlign={justify}>
          <LinkOverlay
            as={Link}
            to={item?.link?.url || urlResolver(item, routes.PRODUCT)?.url}
            title={item?.title}
            onClick={handleTrackingClick}
          >
            <Text as="h3" textStyle="paragraph" fontSize={small ? "14px" : "16px"} mb="0" lineHeight="21px" maxW={["100%", "90%"]}>
              {item?.title}
            </Text>
          </LinkOverlay>
          {item.variants?.[0] && <ProductPrice variant={item.variants?.[0]} small={true} />}
        </Box>
      </LinkBox>
    </GridItem>
  )
}

export default React.memo(FeaturedProductsItem)
