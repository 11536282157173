import React from "react"
import { Button, HStack } from "@chakra-ui/react"
import { useCart } from "@app/hooks/useCart"

type Props = {
  product: any
  variant: any
  loading: boolean
  handleGiftCard: (state: boolean) => void
}

const ProductAddToCart: React.FC<Props> = ({ product, variant, loading, handleGiftCard }) => {
  const { addToCart, loading: cartLoading } = useCart()

  const isGiftCard = product.productType === "Gift Card"

  const getText = () => {
    if (!variant) {
      return isGiftCard ? "Please select a value" : "Please select a size"
    }

    return variant?.availableForSale ? "Add to Cart" : "Sold out"
  }

  const handleAction = () => {
    if (isGiftCard) {
      handleGiftCard(true)
    } else {
      addToCart(variant?.id)
    }
  }

  return (
    <>
      <HStack spacing={4} mt={10} mb={4} align="stretch" display={["none", "block"]}>
        <Button w="100%" onClick={handleAction} disabled={!variant?.availableForSale || loading} isLoading={cartLoading}>
          {getText()}
        </Button>
      </HStack>
    </>
  )
}

export default React.memo(ProductAddToCart)
