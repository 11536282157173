import React from "react"
import { Box, Text } from "@chakra-ui/react"

type Props = {
  product: {
    description: any
  }
}

const ProductDescription: React.FC<Props> = ({ product }) =>
  product?.description ? (
    <Box mb={4} mt={[6, 9]}>
      <Text
        as="div"
        fontSize={14}
        lineHeight={"24px"}
        color="grey.700"
        className="rte"
        dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }}
      />
    </Box>
  ) : null

export default React.memo(ProductDescription)
