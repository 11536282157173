import React, { useEffect, useMemo, useState } from "react"
import { Box, Container, Grid, Heading } from "@chakra-ui/react"
import FeaturedProductsItem from "@app/components/Sections/FeaturedProducts/FeaturedProductsItem"
import { useShopify } from "@app/hooks/useShopify"

type Props = {
  id?: string
  title: string
  related: any
}

const ProductRecommended: React.FC<Props> = ({ id, title, related }) => {
  const { getHandle, getRecommendations, getProducts } = useShopify()
  const [items, setItems] = useState([])

  const fetchItems = useMemo(
    () => async () => {
      const items =
        related?.length > 0
          ? (await getProducts({ firstImages: 10, firstVariants: 99, handles: related?.map((item: any) => getHandle(item)) })).filter(
              product => product.id
            )
          : []
      const normalisedProducts = items?.products || items
      if (normalisedProducts) setItems(normalisedProducts)
    },
    [related, getProducts, id, getRecommendations, getHandle]
  )

  // intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return items?.length ? (
    <Box as="section" bg="brand.light">
      <Container maxW="container.lg" mb={8} px={[6, 4]}>
        <Box py={[8, 12]} borderTop="1px solid" borderColor="grey.300">
          <Heading as="h2" size="h2" textAlign="center" mb={[8, 12]}>
            {title}
          </Heading>
          <Grid templateColumns={["repeat(2, 1fr)", "repeat(4, 1fr)"]} gap={4}>
            {items?.map((item, index) => (
              <FeaturedProductsItem key={item?.id?.toString()} index={index + 1} item={item} small={true} />
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(ProductRecommended)
