import React from "react"
import { AspectRatio, Box, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"

import "../../theme/css/swiper.css"

import SwiperCore, { Navigation, Pagination } from "swiper/core"
import { getResponsiveProductImages } from "@app/utils/getProductImages"

SwiperCore.use([Navigation, Pagination])

type Props = {
  product: {
    media: any
    title: string
  }
}

const ProductMedia: React.FC<Props> = ({ product }) => {
  const { desktopImages, mobileImages } = getResponsiveProductImages(product?.media)
  const { isOpen, onOpen, onClose } = useDisclosure()
  return product?.media?.length ? (
    <>
      <Box display={["none", "block"]}>
        {desktopImages.length > 0 &&
          desktopImages.map((image: any, index: number) => (
            <Box key={index} onClick={onOpen} cursor="zoom-in">
              <AspectRatio ratio={595 / 592} w="100%" mx="auto">
                <Image
                  src={image?.src}
                  alt={image?.alt || product?.title}
                  w="100%"
                  fallbackSrc="https://plchldr.co/i/820x820?&amp;bg=EFEFEF&amp;fc=EFEFEF"
                />
              </AspectRatio>
            </Box>
          ))}
      </Box>
      <Box display={["block", "none"]} position="relative" mt={4} pb={12} mb={-10}>
        <Swiper navigation={true} pagination={{ clickable: true, el: "#media-pagination" }}>
          {mobileImages.map((image: any, index: number) => (
            <SwiperSlide key={index}>
              <AspectRatio ratio={414 / 532} w="100%" mx="auto">
                <Image
                  src={image?.src}
                  alt={image?.alt || product?.title}
                  mx="auto"
                  fallbackSrc="https://plchldr.co/i/767x767?&amp;bg=EFEFEF&amp;fc=EFEFEF"
                />
              </AspectRatio>
            </SwiperSlide>
          ))}
        </Swiper>
        <Box pos="absolute" bottom={0} insetX={0} id={`media-pagination`} />
      </Box>
      {desktopImages.length > 0 && (
        <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size="full" motionPreset="none" display={["none", "block"]}>
          <ModalOverlay />
          <ModalContent m="0" borderRadius="0">
            <ModalCloseButton
              borderRadius="0"
              zIndex="100"
              size="lg"
              m="16px"
              _focus={{
                boxShadow: "none",
              }}
            />
            <ModalBody p="0">
              {desktopImages.map((image: any, index: number) => (
                <Box key={index}>
                  <AspectRatio ratio={595 / 592} maxW="1800px" w="100%" mx="auto">
                    <Image
                      src={image?.src}
                      alt={image?.alt || product?.title}
                      w="100%"
                      fallbackSrc="https://plchldr.co/i/1440x1440?&amp;bg=EFEFEF&amp;fc=EFEFEF"
                    />
                  </AspectRatio>
                </Box>
              ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  ) : (
    <>
      <Box display={["none", "block"]}>
        <Box mb={6}>
          <AspectRatio ratio={595 / 592} w="100%" mx="auto">
            <Image w="100%" fallbackSrc="https://plchldr.co/i/820x820?&amp;bg=EFEFEF&amp;fc=EFEFEF" alt={product?.title} />
          </AspectRatio>
        </Box>
      </Box>
      <Box display={["block", "none"]} position="relative" mt={4}>
        <Box>
          <AspectRatio ratio={414 / 532} w="100%" mx="auto">
            <Image w="100%" fallbackSrc="https://plchldr.co/i/820x820?&amp;bg=EFEFEF&amp;fc=EFEFEF" alt={product?.title} />
          </AspectRatio>
        </Box>
      </Box>
    </>
  )
}
export default React.memo(ProductMedia)
